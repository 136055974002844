.home-wrapper {
  background-image: url("./assets/Ellipse 4.png");
  background-repeat: no-repeat;
  background-position-x: inherit;
  font-family: "Poppins", sans-serif;
}
.logo {
  padding-top: 47px;
  display: flex;
  align-items: center;
}
.logo span {
  font-size: 32px;
  margin-left: 13px;
  font-weight: 600;
  margin-top: 2%;
}
.hero-content {
  max-width: 556px;
}

.full-width-on-mobile {
  width: 50%;
  padding-right: 1em;
}

.search-zipcode {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}

.search-zipcode > .search:nth-child(1) {
  padding-right: 1em;
  width: 70%;
}

.search-zipcode > .search:nth-child(2) {
  width: 30%;
}

.hero-content span {
  font-size: 2em;
  font-weight: 600;
  margin-left: 0.8em;
  font-family: "Poppins", sans-serif;
}
.hero-content h1 {
  margin-top: 98px;
  font-size: 48px;
  line-height: 72px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.hero-content h4 {
  font-weight: bold;
  font-size: 1.25em;
  margin-block: 1em;
}
.hero-content p {
  margin-bottom: 2.56em;
  font-size: 18px;
  line-height: 27px;
  font-family: "Raleway", sans-serif !important;
}
.hero-section .left {
  text-align: end;
}
.hero-btn {
  background-color: #0a91bf;
  color: white;
  cursor: pointer;
  padding-block: 17px;
  border: none;
  cursor: pointer;
  font-size: 1.25em;
  font-weight: 600;
  padding-inline: 3.12em;
}
.container-wrapper {
  background-color: #eceff2;
  background: #eceff2;
  padding-block: 80px;
}
.feature-content {
  display: flex !important;
  align-items: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.feature-content p {
  font-size: 12px;
  /* width: 88%; */
  line-height: 20px;
  font-family: "Raleway", sans-serif !important;
}
.feature-content h2 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.feature-wrapper {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

.frequently {
  padding-inline: 4.5em;
}
.frequently h1 {
  margin-top: 121px;
  margin-bottom: 63px;
  font-size: 48px;
  font-weight: 600;
}
.frequently h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 36px;
  font-family: "Poppins", sans-serif;
}
.frequently p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif !important;
}
.read-more {
  color: #0c90bd;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 12px;
  font-family: "Raleway", sans-serif !important;
}

.footer-btn {
  text-align: center;
  padding-top: 6em;
  padding-bottom: 146px;
}

.tabs {
  display: flex;
  width: 100%;
}
.tabs .tab {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}

.tab {
  padding-right: 5px;
}

.tabs .tab:not(:last-child):after {
  content: " / ";
  color: "#000";
}

.tabs .tab:not(:first-child) {
  padding-left: 5px;
}

.active {
  color: #0a91bf !important;
  font-weight: bold;
}

.ant-layout-header {
  color: #fff !important;
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 60px !important;
  padding: 4px 15px;
  font-size: 24px !important;
  border-radius: 2px;
  margin-right: 10px;
}

.link-button {
  border: 0px !important;
  text-align: left !important;
  height: 32px !important;
  font-size: 24px !important;
  box-shadow: none !important;
  color: #0a91bf !important;
  text-decoration: underline;
  cursor: pointer;
}

.m-t-50-px {
  margin-top: 50px;
}

.ant-layout-content {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  line-height: 50px;
  background: #fff;
  max-height: 90vh;
}

.ant-layout-footer {
  position: absolute;
  width: 100vw;
  bottom: 0;
}
.question-list {
  max-width: 100vw;
  max-height: 90vh;
}
.overflow-hidden {
  overflow: hidden;
}

.questions {
  width: 50%;
}

.questions div button {
  width: 40%;
}

.question {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}
.question p {
  font-size: 36px;
  margin-bottom: 0.2em;
  line-height: 1.5;
}
.ant-button {
  background-color: #001529;
}
h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

small {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.card {
  margin-top: 20px;
  /* paddingTop: theme.spacing(1) + 4,
    marginTop: theme.spacing(1),
    border: "solid",
    borderRadius: theme.spacing(1) / 2,
    borderColor: "#dadaed",
    borderWidth: 0.5,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    }, */
}

/* Language: css */
/* sticky header */
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
}

.sticky-header + .content {
  margin-top: 50px;
}

.sticky {
  position: fixed;
  top: 100px;
}

.float-right {
  float: right;
}

.consent-container {
  margin-left: auto;
}
/* display: flex; */
.flex {
  display: flex;
}

/* container */
.container {
  box-sizing: border-box;
  padding-inline: 5.6%;
  margin-left: auto;
  margin-right: auto;
}

.back-container {
  margin-left: 5%;
  margin-right: 5%;
}

/* space-between */
.justify-space-between {
  display: flex;
  justify-content: space-between;
}

b {
  display: block;
}

.checked-list {
  list-style: none;
}

.checked-list li:before {
  content: "✓";
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: #fff;
  background-color: #0a91bf;
  border-radius: 20%;
}

.left {
  flex-basis: 50%;
}

.right {
  flex-basis: 50%;
}

.wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.align-center {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.section {
  margin-top: 50px;
  margin-bottom: 10px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.vh-90 {
  height: 90vh;
}

.interview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.scroll-table {
  overflow-y: scroll;
  scrollbar-color: #000;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  color: #000;
}

.allergies {
  line-height: 30px !important;
  align-items: flex-start;
  width: 50%;
  text-align: left;
  margin-top: 10%;
}
.allergies h2,
small {
  margin: 0;
  padding: 0;
}

.allergies .action {
  margin-top: 10px;
}
.chips {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}
.chip {
  display: flex;
  margin: 5px 5px 0 0;
  border: 1px solid gray;
  border-radius: 8px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 2px 2px 2px 4px;
  background: #0a91bf;
  color: #fff;
  white-space: nowrap;
  width: auto;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px !important;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.ant-picker-input input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px !important;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.chip .allergy {
  margin-right: 5px;
}

.allergy,
.close-button {
  line-height: 193%;
}

.chip .close-button {
  border: 1px solid gray;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  padding: 0;
  background: #fff;
  color: #0a91bf;
  cursor: pointer;
}

.vh-50 {
  width: 50%;
}

.error-content {
  width: 50%;
  line-height: 30px;
}

.error-content p {
  padding: 0;
  margin: 0;
}

.error {
  color: #ff0000;
  padding: 0;
  margin: 0;
  font-size: 40px;
}

.error-message {
  color: #ff0000;
  line-height: 20px;
  font-size: medium;
}

form {
  line-height: 30px;
}

.message-fl {
  color: #ff000090;
  margin-top: 10px;
  font-size: 16px;
}

.message-fl::before {
  content: "* ";
}

.logo-app {
  color: #fff;
}

.logo-app:hover {
  color: #fff;
}
.responsive {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 36px;
}

.sub-title {
  font-size: 20px;
}

.search {
  line-height: 30px;
}

.helper-message {
  font-size: 16px !important;
  line-height: 16px;
  color: rgb(73, 77, 85);
  font-weight: 300;
  margin-bottom: 20px !important;
}

.m-t-10-px {
  margin-top: 10px !important;
}

.m-b-10-px {
  margin-bottom: 10px !important;
}

.m-b-0-px {
  margin-bottom: 0px !important;
}

.description-message {
  display: block;
  color: rgb(73, 77, 85) !important;
  line-height: 2;
}

.consent {
  margin-top: 0;
}

.m-t-10-p {
  margin-top: 10%;
}

.street {
  width: 40%;
}

.places {
  margin-right: 20px;
}

.active-pharmacy {
  background-color: #0a91bf;
  color: #fff !important;
  padding: 5px;
  border-radius: 5px;
}

.active-pharmacy div div h4 {
  color: #fff !important;
  padding-left: 5px;
}

.active-pharmacy div div div {
  color: #fff !important;
  padding-left: 5px;
}

.list-item {
  line-height: 1;
}
.upload-button {
  margin-bottom: 2px;
}
.upload-button div span .ant-button {
  min-width: 300px;
}

.details {
  margin: 0;
  padding: 0;
  line-height: 2;
}
.consent-message {
  line-height: 2;
  padding: 0 15px;
  font-size: medium;
  width: 90%;
  border-top: 100px solid #fff;
}
.warning-message {
  line-height: 2;
  font-size: medium;
  width: 90%;
  border-top: 20px solid #fff;
}
.show-on-mobile-and-tablet {
  display: none;
}

.percent-50 {
  flex-basis: 50%;
}

.interview-left {
  margin-right: 20px;
}

.interview-left > div > img {
  width: 100%;
  object-fit: cover;
}

.success {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.extra-padding {
  min-height: 10%;
}
.ant-result-subtitle {
  color: #000 !important;
}
.content-container {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
}
.content-container > h2 {
  text-align: center;
}
.content-container > p {
  text-align: left;
  line-height: 15pt;
}
.content-container > ul > li {
  line-height: 15pt;
  padding-top: 10px;
}
.content-container > ul > li > span {
  font-weight: bold;
  text-decoration: underline;
}
.content-container > p > span > span {
  font-weight: bold;
}
.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: bold;
}
.divider::before,
.divider::after {
  content: "";
  display: block;
  height: 0.1em;
}

.divider::before {
  background-color: #0a91bf;
  width: 100%;
  margin-right: 2vh;
}

.divider::after {
  background-color: #0a91bf;
  width: 100%;
  margin-left: 2vh;
}

.custom-tabs {
  width: 100%;
  height: 3rem;
  border: 1px solid #0a91bf;
}

.custom-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  background-color: white;
  color: black;
}

.tab-active {
  background-color: #0a91bf;
  color: white;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 550px) and (-webkit-min-device-pixel-ratio: 2) {
  .hero-section .left {
    flex-basis: 100;
  }
  .responsive {
    height: 100%;
    max-width: 90%;
  }
  .interview {
    display: none;
  }
  .questions,
  .allergies,
  .consent {
    width: 100%;
  }
  .question p {
    font-size: 28px;
    margin-bottom: 0.2em;
    text-align: justify;
  }
  .ant-checkbox-wrapper {
    line-height: inherit;
  }
  .left {
    flex-basis: 100%;
  }

  .image {
    display: none;
  }

  .right {
    flex-basis: 100%;
    margin-top: 1rem;
  }

  .column-on-mobile {
    flex-direction: column;
  }
  .tabs {
    width: 100%;
  }
  .tab {
    font-size: x-small;
  }
  .justify-space-between {
    flex-direction: column;
  }
  .street {
    width: 100%;
  }
  .hide-on-mobile {
    display: none;
  }
  .places {
    margin-right: 0;
  }
  .consent-container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .sticky {
    display: none;
  }
  .show-on-mobile-and-tablet {
    display: block;
  }
  .percent-50 {
    min-width: 100%;
    max-width: 100%;
  }
  .responsive {
    width: 100%;
  }
  .success {
    width: 100%;
  }
  .full-width-on-mobile {
    width: 100%;
  }
  .search-zipcode {
    flex-direction: column;
  }
  .search-zipcode > .search:nth-child(1) {
    padding-right: 0;
    width: 100%;
  }
  .search-zipcode > .search:nth-child(2) {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .responsive {
    height: 100%;
    max-width: 90%;
  }
  .interview {
    display: none;
  }
  .interview-left {
    display: none;
  }
  .questions,
  .allergies,
  .consent {
    width: 100%;
  }
  .question p {
    font-size: 28px;
    margin-bottom: 0.2em;
    text-align: justify;
  }
  .ant-checkbox-wrapper {
    line-height: inherit;
  }
  .left {
    flex-basis: 100%;
  }

  .image {
    display: none;
  }

  .right {
    flex-basis: 100%;
    margin-top: 1rem;
  }

  .column-on-mobile {
    flex-direction: column;
  }
  .tabs {
    width: 100%;
  }
  .tab {
    font-size: small;
  }
  .justify-space-between {
    flex-direction: column;
  }
  .street {
    width: 100%;
  }
  .hide-on-mobile {
    display: none;
  }
  .places {
    margin-right: 0;
  }
  .consent-container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .sticky {
    display: none;
  }
  .show-on-mobile-and-tablet {
    display: block;
  }
  .percent-50 {
    min-width: 100%;
    max-width: 100%;
  }
  .responsive {
    width: 100%;
  }
  .success {
    width: 100%;
  }
  .full-width-on-mobile {
    width: 100%;
  }
  .search-zipcode {
    flex-direction: column;
  }

  .search-zipcode > .search {
    width: 100%;
  }
  .search-zipcode > .search:nth-child(1) {
    padding-right: 1em;
  }
}

.mobileimage {
  display: none;
}

@media screen and (max-width: 1024px) {
  .mobileimage {
    display: flex;
    justify-content: center;
  }
  .hero-content {
    max-width: 100%;
  }
  .hero-content p {
    width: 70%;
  }
  .mobileimage img {
    width: 60%;
    align-self: center;
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .hero-section .left {
    display: none;
  }
  .hero-btn {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .hero-section h1 {
    margin-top: 32px;
  }
  .mobileimage {
    display: flex;
    justify-content: center;
  }
  .hero-content p {
    width: 100%;
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .hero-btn {
    margin-bottom: 20px;
  }
  .hero-section .left {
    display: none;
  }
  .frequently {
    padding-inline: 0px;
  }
  .footer-btn {
    padding-bottom: 192px;
  }
  .feature-content p {
    width: 100%;
  }
  .frequently h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 425px) {
  .container {
    padding-inline: 9.6%;
  }
  .logo span {
    font-size: 24px;
    margin-left: 7px;
  }
  .pick {
    margin-right: 10% !important;
  }
  .logo-img {
    width: 43px;
  }

  .hero-content h4 {
    font-size: 1em;
  }
  .hero-section h1 {
    font-size: 2em;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .hero-content p {
    font-size: 14px;
  }
  .feature {
    margin-bottom: 0px !important;
  }
  .container-wrapper {
    padding-block: 40px;
  }
  .hero-btn {
    padding-inline: 1.12em;
    padding-block: 11px;
    font-size: 1em;
  }
  .frequently h1 {
    margin-top: 55px;
    margin-bottom: 17px;
  }
  .footer-btn {
    padding-bottom: 92px;
  }
}

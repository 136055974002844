.radiobtn {
  position: relative;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
}

.radiobtn label {
  display: block;
  /* background: #B8B0FF; */
  color: #444;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #1ca7d532;
  margin-bottom: 5px;
  cursor: pointer;
}

.radiobtn label::before,
.radiobtn label::after {
  content: "";
  position: absolute;
  right: 11px;
  top: 11px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: lighten(#0A91BF, 15%);
}

.radiobtn label::before {
  background: transparent;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
}

input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  appearance: none;
}

input[type="radio"] + label {
  font-size: 20px;
}

input[type="radio"]:checked + label {
  background: #28b3e2;
  animation-name: blink;
  animation-duration: 1s;
  border-color: #0A91BF;
  color: #FFF;
}

input[type="radio"]:checked + label::before {
  width: 20px;
  height: 20px;
}

input[type="radio"]:checked + label::after {
  background: #0A91BF;
}

@keyframes blink {
	0% {
		background-color: lighten(#0A91BF, 15%);
	}
	10% {
		background-color: lighten(#0A91BF, 15%);
	}
	11% {
		background-color: lighten(#0A91BF, 20%);
	}
	29% {
		background-color: lighten(#0A91BF, 20%);
	}
	30% {
		background-color: lighten(#0A91BF, 15%);
	}
	50% {
		background-color: lighten(#0A91BF, 20%);
	}
	45% {
		background-color: lighten(#0A91BF, 15%);
	}
	50% {
		background-color: lighten(#0A91BF, 20%);
	}
	100% {
		background-color: lighten(#0A91BF, 15%);
	}
}